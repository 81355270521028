export const badges = {
  html: {
    title: "HTML",
    src: "https://img.shields.io/badge/HTML5-E34F26?style=for-the-badge&logo=html5&logoColor=white",
  },
  css: {
    title: "CSS",
    src: "https://img.shields.io/badge/CSS3-1572B6?style=for-the-badge&logo=css3&logoColor=white",
  },
  javascript: {
    title: "JavaScript",
    src: "https://img.shields.io/badge/JavaScript-323330?style=for-the-badge&logo=javascript&logoColor=F7DF1E",
  },
  typescript: {
    title: "TypeScript",
    src: "https://img.shields.io/badge/TypeScript-007ACC?style=for-the-badge&logo=typescript&logoColor=white",
  },
  react: {
    title: "React",
    src: "https://img.shields.io/badge/React-20232A?style=for-the-badge&logo=react&logoColor=61DAFB",
  },
  "react-native": {
    title: "React Native",
    src: "https://img.shields.io/badge/React_Native-20232A?style=for-the-badge&logo=react&logoColor=61DAFB",
  },
  vue: {
    title: "Vue.js",
    src: "https://img.shields.io/badge/Vue.js-35495E?style=for-the-badge&logo=vue.js&logoColor=4FC08D",
  },
  node: {
    title: "Node.js",
    src: "https://img.shields.io/badge/Node.js-43853D?style=for-the-badge&logo=node.js&logoColor=white",
  },
  php: {
    title: "PHP",
    src: "https://img.shields.io/badge/PHP-777BB4?style=for-the-badge&logo=php&logoColor=white",
  },


  'styled-components': {
    title: "Styled Components",
    src: "https://img.shields.io/badge/styled--components-DB7093?style=for-the-badge&logo=styled-components&logoColor=white",
  },
  'material-ui': {
    title: "Material UI",
    src: "https://img.shields.io/badge/Material--UI-0081CB?style=for-the-badge&logo=material-ui&logoColor=white",
  },
  redux: {
    title: "Redux",
    src: "https://img.shields.io/badge/Redux-593D88?style=for-the-badge&logo=redux&logoColor=white",
  },
  postgres: {
    title: "PostgreSQL",
    src: "https://img.shields.io/badge/PostgreSQL-316192?style=for-the-badge&logo=postgresql&logoColor=white",
  },
  'framer-motion': {
    title: "Framer Motion",
    src: "https://img.shields.io/badge/-framer%20motion-%23a2e?style=flat-square&logo=framer",
  },
  'firebase': {
    title: "Firebase",
    src: "https://img.shields.io/badge/-firebase-%44cc11?style=flat-square&logo=firebase",
  },
  'expo': {
    title: "Expo",
    src: "https://img.shields.io/badge/Expo-%2349505a?style=flat-square&logo=expo",
  },
};

export const techs = [
  badges.html,
  badges.css,
  badges.javascript,
  badges.react,
  badges["react-native"],
  badges.typescript,
  badges.vue,
  badges.node,
  badges.php,
];
