export const education = [
  {
    university: "FATEC - Franca",
    location: 'Franca, Brazil',
    degrees: [
      {
        title: "System Analysis and Development",
        type: `Technologist's degree`,
        CR: "8.5/10",
        duration: {
          start: new Date("2018-06-02"),
          end: new Date("2021-06-30"),
        },
      },
    ],
  },
  {
    university: "Federal University of Pelotas",
    location: 'Pelotas, Brazil',
    degrees: [
      {
        title: "Petroleum Engineering",
        type: `Bachelor's degree`,
        CR: "8.5/10",
        duration: {
          start: new Date("2013-04-02"),
          end: new Date("2018-02-28"),
        },
      },
    ],
  },
];
