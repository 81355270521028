export const workExperiences = [
  {
    company: {
      title: "NinjaOne",
    },
    location: "Austin - Texas, USA",
    jobs: [
      {
        title: "Senior Software Engineer",
        description: "",
        duration: {
          start: new Date("2023-03-27"),
          end: null,
        },
      },
    ],
  },
  {
    company: {
      title: "Accedo.tv",
    },
    location: "Stockholm, Sweden",
    jobs: [
      {
        title: "Senior Software Engineer",
        description: "",
        duration: {
          start: new Date("2022-06-07"),
          end: new Date("2023-03-22"),
        },
      },
    ],
  },
  {
    company: {
      title: "iClinic",
    },
    location: "Ribeirão Preto, Brazil",
    jobs: [
      {
        title: "Senior Front-end Engineer",
        description: "",
        duration: {
          start: new Date("2021-11-02"),
          end: new Date("2022-05-14"),
        },
      },
    ],
  },
  {
    company: {
      title: "Memed - Receita Digital",
    },
    location: "São Paulo, Brazil",
    jobs: [
      {
        title: "Front-end Engineer",
        description: "",
        duration: {
          start: new Date("2020-12-02"),
          end: new Date("2021-10-30"),
        },
      },
    ],
  },
  {
    company: {
      title: "Visor ADL",
    },
    location: "Mexico City, Mexico",
    jobs: [
      {
        title: "Companion Squad Leader",
        description: "",
        duration: {
          start: new Date("2020-10-02"),
          end: new Date("2020-12-30"),
        },
      },
      {
        title: "Front-end Team Leader",
        description: "",
        duration: {
          start: new Date("2020-07-02"),
          end: new Date("2020-12-30"),
        },
      },
      {
        title: "Product Manager",
        description: "",
        duration: {
          start: new Date("2020-01-02"),
          end: new Date("2020-07-30"),
        },
      },
    ],
  },
  {
    company: {
      title: "Jussara",
    },
    location: "Patrocínio Paulista, Brazil",
    jobs: [
      {
        title: "Software Engineer",
        description: "",
        duration: {
          start: new Date("2018-07-02"),
          end: new Date("2020-01-30"),
        },
      },
    ],
  },
  {
    company: {
      title: "Feelsense",
    },
    location: "Franca, Brazil",
    jobs: [
      {
        title: "Software Engineer Intern",
        description: "",
        duration: {
          start: new Date("2018-02-02"),
          end: new Date("2018-06-30"),
        },
      },
    ],
  },
];

export const volunteerExperiences = [
  {
    company: {
      title: "Academic Directory Karen Adami (UFPel)",
    },
    location: "Pelotas, Brazil",
    jobs: [
      {
        title: "Vice-President",
        description: "",
        duration: {
          start: new Date("2015-12-02"),
          end: new Date("2016-04-30"),
        },
      },
      {
        title: "Web Designer",
        description: "",
        duration: {
          start: new Date("2015-01-02"),
          end: new Date("2015-12-30"),
        },
      },
      {
        title: "Paymaster",
        description: "",
        duration: {
          start: new Date("2014-07-02"),
          end: new Date("2014-12-30"),
        },
      },
    ],
  },
  {
    company: {
      title: "Petro-Sul (UFPel)",
    },
    location: "Pelotas, Brazil",
    jobs: [
      {
        title: "Co-Founder",
        description: "",
        duration: {
          start: new Date("2014-06-02"),
          end: new Date("2016-04-30"),
        },
      },
    ],
  },
];

export const internshipExperiences = [
];
