<template>
  <div>
    <h2>Projects</h2>

    <div class="inner-content">
      <ul class="list-map">
        <li v-for="project in this.projects" :key="project.key">
          <fieldset class="experience-card">
            <legend>{{ project.title }}</legend>
            <div class="project-header">
              <img :src="project.logo" :alt="project.title" class="project-logo">

              <div class="project-stacks stacks">
                <img v-for="stack in project.stacks" :key="stack" :src="badges[stack].src" :alt="badges[stack].title"
                  class="tech-badge" />

              </div>

            </div>

            <div class="links">
              <a :href="project.repository" target="_blank" rel="noopener noreferrer" class="project-link tooltip"
                data-tooltip="Check project's repository at GitHub">
                <img src="@/assets/img/png/github.png" alt="Open in new tab">
              </a>

              <a :href="project.url" target="_blank" rel="noopener noreferrer" class="project-link tooltip"
                data-tooltip="Check project's website in a new tab">
                <img src="@/assets/img/png/link.png" alt="Open in new tab">
              </a>

            </div>


            <section class="description">
              <p class="paragraph" v-for="description in project.description" :key="description.key">
                {{ description }}
              </p>
            </section>

            <section class="project-banners">
              <img v-for="banner in project.banners" :key="banner.key" :src="banner" :alt="project.title" />
            </section>

            <div class="project-tag-date">
              <p class="subtitle project-info badge" :id="project.type">
                {{ project.type }}
              </p>

              <p class="subtitle project-info">
                {{ formatDate(project.date) }}
              </p>
            </div>
          </fieldset>
        </li>
      </ul>
      <a class="read-more"
        href="https://github.com/leoronne?tab=repositories"
        target="_blank">
        + See all projects on GitHub
      </a>
    </div>
  </div>
</template>

<style scoped>
.project-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0;
}
.project-header .project-stacks {
  margin-top: 15px;
}

.project-header .project-logo {
  max-width: 130px;
}

.links {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links .project-link img {
  width: 15px;
  height: 15px;
}

.project-banners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.project-banners img {
  width: 100%;
  max-width: 600px;
}

.project-tag-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

#ui-clone {
  background: #c2cffc;
}

#personal-project {
  background: #c2ced1;
}

#nlw-challenge {
  background: #d1c3f3;
}
</style>

<script>
import { projects } from '../../data/projects';
import { badges } from '../../data/badges';

export default {
  data: function () {
    return {
      projects,
      badges
    }
  },
  methods: {
    formatDate(date) {
      return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    },
  }
}
</script>
