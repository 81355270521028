const unsortedProjects = [
  // {
  //   title: "Be The Hero",
  //   repository: "https://github.com/leoronne/BeTheHero",
  //   date: new Date("2020-03-29"),
  //   url: "https://bethehero-25bcf.firebaseapp.com/",
  //   description: [
  //     "Be The Hero connects people who want to help Non Governmental Organizations (NGO). At the platform NGOs can create incidents which they need a hero to help. The heroes can search through all the incidents created and choose which one they want to help and contact the NGO.",
  //     "It was a project made on Rocketseat's #11 OmniStack Week, with a few changes.",
  //   ],
  //   stacks: ["node", "react", "react-native"],
  //   logo: "https://raw.githubusercontent.com/leoronne/BeTheHero/master/client/src/assets/img/logo.png",
  //   type: "challenge",
  // },
  {
    title: "Tesla - UI Clone",
    repository: "https://github.com/leoronne/tesla-homepage-ui-clone",
    date: new Date("2020-12-22"),
    url: "https://tesla.ui-clone.ronne.dev/",
    description: [
      "This project was part of an UI Clone challenge, the idea was to replicate Tesla homepage using ReactJS and framer-motion, the last one intended to mimic Tesla's animations when scrolling through the page sections.",
    ],
    stacks: ["react", "typescript", "framer-motion", "styled-components"],
    logo: "https://github.com/leoronne/tesla-homepage-ui-clone/blob/master/public/logo1024.png?raw=true",
    banners: [
      "https://raw.githubusercontent.com/leoronne/tesla-homepage-ui-clone/master/src/assets/img/banner.png",
    ],
    type: "ui-clone",
  },
  {
    title: "GitHub - UI Clone",
    repository: "https://github.com/leoronne/github-ui-clone",
    date: new Date("2020-12-22"),
    url: "https://github.ui-clone.ronne.dev/",
    description: [
      "This project was part of an UI Clone challenge, the idea was to replicate (partially) the GitHub's profile and repository pages using it's public APIs. It was made using ReactJS, MUI and styled-components to build a Dark/Light theme.",
      "The user can also navigate through different users and repositories.",
    ],
    stacks: ["react", "typescript", "styled-components", "material-ui"],
    logo: "https://raw.githubusercontent.com/leoronne/github-ui-clone/master/public/logo1024.png?raw=true",
    banners: [
      "https://github.com/leoronne/github-ui-clone/raw/master/src/assets/img/banner.png",
    ],
    type: "ui-clone",
  },
  {
    title: "Twitter - UI Clone",
    repository: "https://github.com/leoronne/twitter-ui-clone",
    date: new Date("2020-12-23"),
    url: "https://twitter.ui-clone.ronne.dev/",
    description: [
      "This project was part of an UI Clone challenge, the idea was to replicate (partially) the Twitter's profile page using ReactJS, MUI and styled-components.",
    ],
    stacks: ["react", "typescript", "styled-components", "material-ui"],
    logo: "https://github.com/leoronne/twitter-ui-clone/blob/master/public/logo1024.png?raw=true",
    banners: [
      "https://github.com/leoronne/twitter-ui-clone/raw/master/src/assets/img/banner.png",
    ],
    type: "ui-clone",
  },
  {
    title: "Discord - UI Clone",
    repository: "https://github.com/leoronne/discord-ui-clone",
    date: new Date("2020-12-23"),
    url: "https://discord.ui-clone.ronne.dev/",
    description: [
      "This project was part of an UI Clone challenge, the idea was to replicate (partially) the Discord's channels page using ReactJS, MUI and styled-components.",
      "The intent of this project was to work with more complex grid attributes of CSS.",
    ],
    stacks: ["react", "typescript", "styled-components", "material-ui"],
    logo: "https://github.com/leoronne/discord-ui-clone/blob/master/public/logo1024.png?raw=true",
    banners: [
      "https://github.com/leoronne/discord-ui-clone/raw/master/src/assets/img/banner.png",
    ],
    type: "ui-clone",
  },
  {
    title: "LinkedIn - UI Clone",
    repository: "https://github.com/leoronne/linkedin-ui-clone",
    date: new Date("2021-01-06"),
    url: "https://linkedin.ui-clone.ronne.dev/",
    description: [
      "This project was part of an UI Clone challenge, the idea was to replicate (partially) the LinkedIn's feed page using ReactJS, MUI and styled-components.",
      "The intent of this project was to work with the the characteristic shrimmer effect from LinkedIn, it also has the old/new version of it's UI.",
    ],
    stacks: ["react", "typescript", "styled-components", "material-ui"],
    logo: "https://github.com/leoronne/linkedin-ui-clone/blob/master/public/logo1024.png?raw=true",
    banners: [
      "https://github.com/leoronne/linkedin-ui-clone/raw/master/src/assets/img/banner.png",
    ],
    type: "ui-clone",
  },
  {
    title: "Dropbox - UI Clone",
    repository: "https://github.com/leoronne/dropbox-homepage-ui-clone",
    date: new Date("2020-12-23"),
    url: "https://dropbox.ui-clone.ronne.dev/",
    description: [
      "This project was part of an UI Clone challenge, the idea was to replicate Dropbox's homepage using ReactJS, MUI and styled-components.",
      "The intent of this project was to work with the color filled transitions through different sections, it also has support for different languages.",
    ],
    stacks: ["react", "typescript", "styled-components", "material-ui"],
    logo: "https://github.com/leoronne/dropbox-homepage-ui-clone/blob/master/public/logo1024.png?raw=true",
    banners: [
      "https://github.com/leoronne/dropbox-homepage-ui-clone/raw/master/src/assets/img/banner.png",
    ],
    type: "ui-clone",
  },
  // {
  //   title: "Whats My Path?",
  //   repository: "https://github.com/leoronne/whats-my-path",
  //   date: new Date("2021-10-10"),
  //   url: "https://whatsmypath.ronne.dev/",
  //   description: [
  //     "This project was part of a front-end challenge proposed by iClinic. The purpose of the application is to show the user which side of the Force he's on and who's his master, based on the response time of two SWAPI (a Star Wars API) requests - the determinant here is which request responds first.",
  //     "There are two options for alignment/master: Light, the master will be Luke Skywalker; Dark, the master will be Darth Vader.",
  //   ],
  //   stacks: ["react", "typescript", "styled-components"],
  //   logo: "https://github.com/leoronne/whats-my-path/blob/master/public/logo1024.png?raw=true",
  //   banners: [
  //     "https://github.com/leoronne/whats-my-path/raw/master/misc/img/banner.png",
  //   ],
  //   type: "job-challenge",
  // },
  {
    title: "Square",
    repository: "https://github.com/leoronne/square-landing-page",
    date: new Date("2021-01-08"),
    url: "https://square.frontend-challenge.ronne.dev/",
    description: [
      "This project was part of a front-end challenge proposed by MuchMore Digital. It's a simple landing page for a company called Square.",
    ],
    stacks: ["react", "typescript", "styled-components"],
    logo: "https://github.com/leoronne/square-landing-page/blob/master/public/logo1024.png?raw=true",
    banners: [
      "https://github.com/leoronne/square-landing-page/raw/master/src/assets/img/banner.png",
    ],
    type: "personal-project",
  },
  {
    title: "Seeker",
    repository: "https://github.com/leoronne/seeker",
    date: new Date("2021-01-19"),
    url: "https://seeker.ronne.dev/",
    description: [
      "Seeker is a basic search engine that let's the user navigate through a huge Comic Book characters database, based on the Game Spot (Comic Vine) public API.",

      "The user can also edit basic information about the character and add it to his favorites list (although, it's stored on the local storage of the browser).",
    ],
    stacks: ["react", "node", "typescript", "styled-components", "material-ui"],
    logo: "https://github.com/leoronne/seeker/raw/master/client/src/assets/img/transparentBanner.png?raw=true",
    banners: [
      "https://github.com/leoronne/seeker/raw/master/client/src/assets/img/banner.png",
    ],
    type: "personal-project",
  },
  {
    title: "Let Me Ask",
    repository: "https://github.com/leoronne/letmeask",
    date: new Date("2021-06-28"),
    url: "https://letmeask.ronne.dev/",
    description: [
      "Let Me Ask is a simple application that lets the user create real-time Q&A rooms and organize their audience questions (pin, favorite or mark as answered). It was made using the Real Time Database from Firebase.",

      "It was firstly idealized by @Rocketseat's Next Level Week #6 - Together.",
    ],
    stacks: [
      "react",
      "typescript",
      "styled-components",
      "material-ui",
      "firebase",
    ],
    logo: "https://github.com/leoronne/letmeask/raw/master/src/assets/img/png/transparentBanner.png?raw=true",
    banners: [
      "https://github.com/leoronne/letmeask/raw/master/src/assets/img/png/banner.png",
    ],
    type: "nlw-challenge",
  },
  {
    title: "Proffy",
    repository: "https://github.com/leoronne/proffy",
    date: new Date("2020-08-05"),
    url: "https://proffy.ronne.dev/",
    description: [
      "Proffy is a simple project made to connect teachers to students, the users can register their information and available hours/price if they want to teach or search available classes from other teachers, based on knowledge field. ",

      "It was idealized by Rocketseat's Next Level Week #2.",
    ],
    stacks: [
      "react",
      "react-native",
      "node",
      "typescript",
      "expo",
      "styled-components",
      "material-ui"
    ],
    logo: "https://github.com/leoronne/proffy/raw/master/assets/img/favicon.png",
    banners: [
      "https://github.com/leoronne/proffy/raw/master/assets/img/banner.png",
    ],
    type: "nlw-challenge",
  },
];

export const projects = unsortedProjects.sort((a, b) => b.date - a.date);
