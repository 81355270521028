<template>
  <div>
    <header>
      <div class="avatar-container">
        <img class="self" src="https://avatars.githubusercontent.com/u/47757685?v=4" alt="Avatar" />
      </div>
      <div class="info-container">
        <h2 class="header-name">Leonardo Cruz (Ronne)</h2>
        <div class="subtitle-container">
          <h4 class="subtitle">Senior Software Engineer - NinjaOne</h4>
          <h4 class="subtitle">São Paulo, Brazil 📍</h4>
        </div>
        <div class="badge-grid stacks">
          <img class="tech-badge" v-for="tech in this.techs" :key="tech.key" :alt="tech.title" :src="tech.src" />
        </div>
      </div>
    </header>
    <div class="inner-content">
      <h3 class="heading"><b>About Me</b></h3>
      <p class="paragraph">Hi there! My name is Leonardo, but you can call me Ronne. I'm a passionate Software Engineer
        from Brazil who thrives on challenges and innovative ideas that merge technology with the needs of
        users. I believe in creating solutions and products that not only have commercial or social value but also make
        people's lives easier by understanding their unique characteristics.
      </p>
      <p class="paragraph">
        I discovered my love for programming at a young age, starting with the basics of web development such as HTML,
        CSS, and JavaScript. Although I initially viewed it as a hobby, it wasn't long before I realized that programming
        was my true calling.
      </p>
      <p class="paragraph">
        Despite holding a bachelor's degree in Petroleum Engineering, my passion for programming led
        me to pursue a career as a full-time programmer after graduation. I enrolled in a Systems Analysis and Development
        course and haven't looked back since.
      </p>
      <p class="paragraph">
        Throughout my professional journey, I've worked in various capacities, including Product
        Manager, Squad Leader, and Fullstack Engineer. However, my focus is currently on front-end development and I'm
        thrilled to be working as a Software Engineer at NinjaOne.
      </p>
      <p class="paragraph">Please feel free to browse my website to learn more about my experiences and projects. If you
        need anything, don't hesitate to reach out. I'd be happy to connect with you at any time.</p>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>Education</b></h3>


      <ul class="list-map">
        <li v-for="education in this.education" :key="education.key">
          <fieldset class="experience-card">
            <legend>{{ education.university }}</legend>
            <ul class="roles">
              <li class="role-card" v-for="degree in education.degrees" :key="degree.key">
                <section>
                  <span class="job-title">
                    {{ degree.title }}
                  </span>

                  <span class="subtitle duration-date">
                    {{ formatDateText(degree.duration, false) }}
                  </span>
                </section>
              </li>
            </ul>
            <p class="subtitle location">
              {{ education.location }} 📍
            </p>
          </fieldset>
        </li>
      </ul>
      <a class="read-more"
        href="https://www.linkedin.com/in/leoronne/details/education/?profileUrn=urn%3Ali%3Afsd_profile%3AACoAABXW04AB1Xj4bZ0eO082ruYsYxgHoTxmgto&locale=en_US"
        target="_blank">
        + Read more
      </a>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>Work Experience</b></h3>


      <ul class="list-map">
        <li v-for="experience in this.workExperiences" :key="experience.key">
          <fieldset class="experience-card">
            <legend>{{ experience.company.title }}</legend>
            <ul class="roles">
              <li class="role-card" v-for="job in experience.jobs" :key="job.key">
                <section>
                  <span class="job-title">
                    {{ job.title }}
                  </span>

                  <span class="subtitle duration-date">
                    {{ formatDateText(job.duration) }}
                  </span>
                </section>
              </li>
            </ul>
            <p class="subtitle location">
              {{ experience.location }} 📍
            </p>
          </fieldset>
        </li>
      </ul>
      <a class="read-more" href="https://www.linkedin.com/in/leoronne/details/experience/?locale=en_US" target="_blank">
        + Read more
      </a>
    </div>

    <div class="inner-content">
      <h3 class="heading"><b>Volunteer Experience</b></h3>


      <ul class="list-map">
        <li v-for="experience in this.volunteerExperiences" :key="experience.key">
          <fieldset class="experience-card">
            <legend>{{ experience.company.title }}</legend>
            <ul class="roles">
              <li class="role-card" v-for="job in experience.jobs" :key="job.key">
                <section>
                  <span class="job-title">
                    {{ job.title }}
                  </span>

                  <span class="subtitle duration-date">
                    {{ formatDateText(job.duration) }}
                  </span>
                </section>
              </li>
            </ul>
            <p class="subtitle location">
              {{ experience.location }} 📍
            </p>
          </fieldset>
        </li>
      </ul>
      <a class="read-more" href="https://www.linkedin.com/in/leoronne/details/volunteering-experiences/?locale=en_US"
        target="_blank">
        + Read more
      </a>
    </div>
  </div>
</template>

<style scoped>
header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inner-content {
  margin-top: 15px;
}

.subtitle-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subtitle-container .subtitle {
  letter-spacing: 0.2px;
}

.badge-grid {
  margin-top: 5px;
  max-width: 600px;
}

.self {
  width: 100%;
  max-width: 250px;
  height: auto;
}

.heading {
  padding-bottom: 5px;
}


.experience-card .location {
  margin-top: 5px;
  text-align: right;
}

.list-map .roles {
  padding: 0 15px;
}

.list-map .roles .role-card {
  list-style: square;
}

.list-map .roles li+li {
  margin-top: 10px;

}

.list-map .roles .role-card section {
  display: flex;
  flex-direction: column;
  gap: 5px;
}


@media (max-width: 512px) {
  .experience-card .location {
    margin-top: 15px;
  }

  .list-map .roles {
    padding-top: 5px;
  }

  .list-map .roles li+li {
    margin-top: 15px;

  }
}

@media (max-width: 768px) {
  header {
    flex-direction: column;
  }
}
</style>
<script>
import { workExperiences, volunteerExperiences } from '../../data/experiences';
import { techs } from '../../data/badges';
import { education } from '../../data/education';

export default {
  data: function () {
    return {
      education,
      workExperiences,
      volunteerExperiences,
      techs
    }
  },
  methods: {
    monthDiff(dateFrom, dateTo) {
      const months = dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear())) + 1;
      const years = Math.floor(months / 12);

      const yearsText = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : null;
      const monthsText = (months % 12) > 0 ? `${months % 12} month${(months % 12) > 1 ? 's' : ''}` : null;

      return `
                ${yearsText || ''}
                ${yearsText && monthsText ? ` and ` : ''}
                ${monthsText || ''}
            `;
    },
    formatDate(date) {
      return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    },
    formatDateText(date, showDuration = true) {
      const now = new Date();
      const start = this.formatDate(date.start);
      const end = date.end ? this.formatDate(date.end) : null;
      const duration = this.monthDiff(date.start, date.end ? date.end : now);
      const durationText = showDuration ? ` · ${duration}` : ''

      return `${start} - ${end || 'Present'}${durationText}`;
    }
  }
}
</script>
