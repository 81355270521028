<template>
    <div class="menu" style="z-index: 1000000;">
        <div class="sidebar">
            <img class="sidebar-image" src="@/assets/img/png/sidebar-image.png"/>
        </div>
        <div class="socials">
            <a href="https://www.linkedin.com/in/leoronne/" target="_blank">
            <div class="bar">
                <img class="social-image" src="@/assets/img/webp/Linkedin.webp" alt="LinkedIn" />
                <u>L</u>inkedIn
            </div>
            </a>
            <a href="https://github.com/leoronne" target="_blank">
            <div class="bar">
                <img class="social-image" src="@/assets/img/webp/Github.webp" alt="GitHub"/>
                <u>G</u>itHub
            </div>
            </a>
            <a href="https://www.instagram.com/leoronne/" target="_blank">
            <div class="bar">
                <img class="social-image" src="@/assets/img/webp/Instagram.webp" alt="Instagram"/>
                <div class="social-text"><u>I</u>nstagram</div>
            </div>
            </a>
            <div class="divider"></div>
            <div class="bar" style="cursor: pointer;" @click="$emit('openWindow', 'ResumeWindow')">
                <img class="social-image" src="@/assets/img/png/resume.png" alt="Résumé" />
                <u>R</u>esume
            </div>
            <div class="bar" style="cursor: pointer;" @click="$emit('openWindow', 'AboutWindow')">
                <img class="social-image" src="@/assets/img/png/help.png"  alt="About"/>
                <u>A</u>bout
            </div>
        </div>
    </div>
</template>
<style scoped>
.menu {
    width: 165px;
    height: 240px;
    background: black;
    background: rgb(195, 195, 195);
    overflow: hidden;
    border-top: solid rgb(250, 250, 250) 2px;
    border-left: solid rgb(250, 250, 250) 2px;
    border-right: solid rgb(90, 90, 90) 1.5px;
    border-bottom: solid rgb(90, 90, 90) 1.5px;
    box-shadow: 1.5px 1.5px black;
    max-height: 100%;
    max-width: 100%;
    align-items: flex-end;
    outline: rgb(222, 222, 222) 1px solid;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 12px;
}

a {
    color: black;
    text-decoration: none;
}

.sidebar {
    width: 25px;
    background: rgb(123, 125, 123);
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.sidebar-image {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .sidebar {
        width: 25px;
        background: rgb(123, 125, 123);
        height: 100%;
        display: flex;
        align-items: flex-end;
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: rgb(123, 125, 123);
    border-bottom: white solid 1px;
}

.socials {
    flex-grow: 1;
}

.social-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 6px;
}

.bar {
    display: flex;
    flex-direction: row;
    padding: 5px 10px 5px 10px;
    align-items: center;
}

.bar:hover {
    background: rgb(0, 0, 118);
    color: white;
}
</style>
<script>
export default {

}
</script>
